import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/map1.png';
const _hoisted_1 = {
  class: "about-us"
};
const _hoisted_2 = {
  class: "about-us__box"
};
const _hoisted_3 = {
  class: "about-us__title"
};
const _hoisted_4 = {
  class: "mb-4"
};
import { router } from '@/router';
export default {
  __name: 'AboutUs',
  setup(__props) {
    const onClickLeft = () => {
      router.back();
    };
    const jump = title => {
      router.push({
        path: '/webview',
        query: {
          title
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-grey__bg",
        fixed: "",
        placeholder: "",
        title: _ctx.$t('about_us'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, [_cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Polarsteps')), 1)]), _createElementVNode("div", {
        class: "about-us__item",
        onClick: _cache[0] || (_cache[0] = $event => jump('Recharge Instructions'))
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Recharge')), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })]), _createElementVNode("div", {
        class: "about-us__item",
        onClick: _cache[1] || (_cache[1] = $event => jump('Withdrawal Instructions'))
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Withdrawal')), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })]), _createElementVNode("div", {
        class: "about-us__item",
        onClick: _cache[2] || (_cache[2] = $event => jump('User agreement'))
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('User agreement')), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })]), _createElementVNode("div", {
        class: "about-us__item",
        onClick: _cache[3] || (_cache[3] = $event => jump('Privacy'))
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Privacy')), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })]), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('Check version')), 1), _cache[5] || (_cache[5] = _createElementVNode("p", {
        style: {
          "font-size": "13px"
        }
      }, "5.0.130000", -1))])]);
    };
  }
};